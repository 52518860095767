.btn-ios {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 3.6rem;
  border-radius: 0 !important;
  font-size: 1.1rem;
  font-weight: 500;

  &:hover {
    background-color: #f2f4f7;
  }
}

//Image upload style
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;

  // position: relative;
  // border-radius: 1rem;

  // .svg-icon {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   z-index: 10;
  // }

  &:hover {
    transition: 0.3s ease;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15) !important;

    .svg-icon {
      visibility: visible !important;
    }
  }
}

.img-thumbnail-border {
  &:first-child {
    border: 1px solid $primary !important;
  }
}

.thumbnail-200 {
  cursor: pointer;
  object-fit: contain;
  min-width: 200px;
  max-width: 200px;
  max-height: 200px;
  border-radius: 0.3rem;
}

.next-position {
  border: 1px dashed #3699ff !important;

  .thumbnail-200 {
    filter: blur(4px);
    opacity: 0.2 !important;
  }
}

.card-height {
  min-height: 70vh;
}

.min-50-height {
  min-height: 50vh;
}

.sub-card-height {
  min-height: 16rem;
}

.w-140px {
  width: 140px !important;
}

.mw-200px {
  min-width: 200px !important;
}

.text-gray {
  color: #d3d3d3 !important;
}

// Block tasarım card style
.card-box {
  background-color: #eef0f8 !important;
  box-shadow: none !important;

  > .card-header {
    border-radius: calc(0.5rem - 1px) !important;
  }

  > .card-body {
    padding: 1.5rem 0 0 0 !important;
  }
}

.card-subheader {
  min-height: 55px !important;
}

@include media-breakpoint-down(sm) {
  .card-subheader {
    min-height: 50px !important;
  }

  .card-header {
    padding: 2rem 1.5rem;
  }
}

.border-secondary-50 {
  border-color: #444444 !important;
}

.images-container {
  border-radius: 1rem;
  border: 2px dashed #cbd5e1;

  &:hover {
    border: 2px dashed #a3cdff !important;
  }
}

//Table hover menu
.hover-menu {
  display: none;
  position: absolute;
  right: 35px;
}

tr:hover .hover-menu {
  display: inline-flex !important;
}

.hover-icon {
  display: none;
}

tr:hover .hover-icon {
  display: block !important;
}

.preview:hover .svg-icon {
  visibility: visible !important;
}

.preview:hover .hide-icon {
  display: none !important;
}

.item-grid-square {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 1rem;
}

.icons-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
  width: 10em;
}

.edit-table {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  min-height: 2.2rem;

  &:hover {
    border-color: #ddd;
  }
}

// .react-bootstrap-table {
//   max-height: calc(100vh - 385px) !important;
// }

.react-bs-table-no-data {
  padding: 7.5rem 0 !important;
  text-align: center;
  color: #a6a6a6;
  font-size: 1.3rem;
  background-color: white;
}

.text-shadow {
  text-shadow: 1px 1px #000;
}

.z-index-10 {
  z-index: 10;
}

.bg-transparent .modal-content {
  background-color: transparent !important;
}

.modal-close-btn {
  position: absolute;
  top: 5px;
  right: -30px;
  z-index: 10;
  opacity: 1 !important;
}

.spinner-center {
  position: absolute;
  top: 30%;
  left: 50%;
}

// Küçük table header için renk değişimi
// .table-header-bg {
//   background-color: #FBFAFA !important;
// }

// Yazdirma sayfa boyutu ayarlama
// @media print {
//   body {
//     width: 14.8cm;
//     height: 21cm;
//     transform: scale(0.8);
//     transform-origin: top left;
//   }
// }

.modal {
  .modal-header {
    padding: 1.1rem 1.75rem;

    .modal-title {
      display: flex;
      align-items: center;
    }
  }

  .modal-footer {
    padding: 0.7rem 1.75rem;

    .btn {
      padding: 0.55rem 0.75rem;
      font-size: 0.925rem;
      line-height: 1.35;
      border-radius: 0.5rem;
      font-weight: 500;
    }
  }
}
