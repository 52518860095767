//
// React-Pdf
// 

$page-width: 794px;
$page-height: 1123px;

.react-pdf_container {
    width: 800px;
    height: 1200px;
    margin: 0;
    padding: 0;
    .react-pdf_action {
        text-align: center;
    }
    .react-pdf_wrapper {
        border: 1px solid darkgray;
        width: $page-width;
        height: $page-height;
        .react-pdf_pages {
            display: flex;
            flex-flow: column;
            justify-content: space-evenly;
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            margin: 0;
            p {
                font-size: 12px;
                margin: 0;
            }
            .container {
                .pdf-img-contain {
                    margin-bottom: 12px;
                    div {
                        min-height: 160px;
                        max-width: 240px;
                        max-height: 160px;
                        img {
                            background-position: center;
                            background-size: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .pdf-adress-line {
                    border-top: 1px solid darkgray;
                    border-bottom: 1px solid darkgray;
                }
                .pdf-table-part {
                    margin-top: 40px;
                }
            }
        }
    }
}