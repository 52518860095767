.card-button {
   overflow: hidden;
   cursor: pointer;
   transition: 0.3s ease;

   &:hover {
      transition: 0.3s ease;
      box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15) !important;
   }
   
   &:disabled {
      cursor: unset;
      opacity: 0.6;
      box-shadow: none !important;
   }

   h5 {
      font-weight: 500;
   }
}