.card-red {
   background: linear-gradient(45deg, #FF5370, #ff869a);
}

.card-blue {
   background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.card-green {
   background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.card-yellow {
   background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.card-background {
   position: absolute;
   display: flex;
   align-items: center;
   font-size: 7.5rem;
   height: 100%;
   // bottom: 2.25rem;
   right: 2.25rem;
   fill: #fff;
   opacity: 0.2;
   // z-index: -1;
}

