.ticket-author {
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   height: 50px;
   width: 50px;
   border-radius: 50%;
   overflow: hidden;
   top: -20px;
   left: -20px;
   z-index: 1;
   background-color: #FFA800;
   color: #FFF4DE;
}

.ticket-author-team {
   left: unset;
   right: -20px;
   background-color: #F64E60;
   color: #FFE2E5;
}

.ticket-body {
   position: relative;
   padding: 24px;
   padding-top: 32px;
   border-radius: 15px;
   width: 90%;
   background-color: #fafafa;
   border: 1px solid #fde5b4
}

.ticket-icon {
   font-size: 18px;
   font-weight: 600;
}

.ticket-team {
   display: flex;
   justify-content: flex-end
}

// .ck .ck-editor__main {
//    max-height: 20rem !important;
//    overflow-y: auto;
// }
