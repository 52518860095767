.ck-editor__editable {
  min-height: 18rem;
  overflow-y: auto;
}

// react-csv-importer css
.CSVImporter_FileSelector {
  border: 2px dashed #cbd5e1;
  padding: 5rem 0;
  background: #f8fafc;
  color: $gray-500;
  font-weight: 600;
  border-radius: 1rem;

  &[data-active="true"] {
    background: $white;
  }

  &:before {
    content: url("/media/svg/filetype-csv.svg");
    font-size: 1.1rem;
    display: block;
    opacity: 0.25;
    transform: scale(4);
    margin: 3rem auto;
    max-width: fit-content;
  }
}

.CSVImporter_FormatRawPreview__scroll {
  background-color: #cecece !important;
}

// .CSVImporter_ColumnDragSourceArea__control {
//    .CSVImporter_IconButton:hover:not(:disabled) {
//       background: $primary !important
//    }

//    .CSVImporter_IconButton:not(:disabled) {
//       background: $primary !important
//    }
// }

.CSVImporter_ImporterFrame__footerNext {
  .CSVImporter_TextButton {
    display: block;
    border: 1px solid transparent !important;
    margin: 0;
    padding: 0.65rem 1rem !important;
    border-radius: 0.4em;
    background: $primary !important;
    color: $white;
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 1.5 !important;
    cursor: pointer;

    &:hover {
      background-color: $primary-hover !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .login-1 {
    height: unset !important;
  }
}

// Dashboard pie chart series label
// apexcharts-legend apexcharts-align-center apx-legend-position-right
.apx-legend-position-right {
  top: 0px !important;
}

// Dashboard pie chart series label
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
  right: 20px !important;
}

.apexcharts-legend-series {
  margin: 4px !important;

  .apexcharts-legend-marker {
    top: 2px !important;
  }

  // Sales by Sales Channel
  .apexcharts-legend-text {
    text-transform: capitalize;
    font-weight: 500 !important;
    color: $gray-500 !important;
  }
}

.apexcharts-tooltip-text-y-label {
  text-transform: capitalize;
}

// .selection-cell,
// .selection-cell-header {
//    position: absolute !important;
//    left: 2.25rem !important;
//    background-color: white;
// }

// .selection-cell-header {
//    margin-top: 18px !important;
// }

.table-hover tbody tr:hover {
  // color: #444444;
  background-color: #f4f8fc;
}

// Carrusel style
.carousel-control-next-icon {
  height: 40px;
  width: 40px;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23ffffff" class="bi bi-chevron-right" viewBox="0 0 16 16"%3e%3cpath fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e');
}

.carousel-control-prev-icon {
  height: 40px;
  width: 40px;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23ffffff" class="bi bi-chevron-left" viewBox="0 0 16 16"%3e%3cpath fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/%3e%3c/svg%3e');
}

.carousel-control-next {
  right: -40px;
  padding-left: 90px;
}

.carousel-control-prev {
  left: -40px;
  padding-right: 90px;
}

.react-bootstrap-table::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  height: 12px;
}

.react-bootstrap-table::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: #d6d6d6;
}

// Editable table validation message q
.react-bootstrap-table-editing-cell {
  position: relative;

  & > .in {
    position: absolute !important;
    top: 3rem;
  }

  & > .alert-danger {
    color: #f64e60 !important;
    background-color: #ffe2e5 !important;
    width: calc(100% - 0.6rem) !important;
    padding: 0.5rem 1rem;
    text-align: end !important;
    border: none !important;
  }
}

.symbol-group .symbol {
  margin-left: -18px;
  // border: 1px solid $white;
}

.dropdown-menu {
  .dropdown-item {
    &.active {
      background-color: $primary-light !important;
    }
  }
}

.tooltip-inner {
  max-width: 350px;
}

.checkbox > span {
  border-radius: 0.45rem;
}

// Number badge
.symbol {
  .symbol-badge-number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 11px;
    color: white;
    border: 0.5px solid $white;
    width: 16px;
    height: 16px;
  }
}
